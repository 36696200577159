.p-button-label {
  font-size: 14px;
}

.p-button.p-button-icon-only {
  width: 2.5rem;
  height: 2.7rem;
}

button.toggle-sidebar-button {
  color:#eeeeee;

  &.p-button.p-button-text:enabled:active {
    background: transparent;
  }

  &.p-button:focus {
    box-shadow: 0 0;
  }
  &.p-button:hover {
   background-color: #e017280a;
   color:#eeeeee;
  }
}

.action-btn {
  &.p-button.p-button-icon-only {
    width: 2.2rem;
    height: 2.2rem;
  }
}

.update-team-btn {
  span.p-button.p-fileupload-choose {
    padding: 0.5rem 1.25rem;
  }
}
