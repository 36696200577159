.login-password.p-password .p-inputtext {
  width: 100%;
}

.p-dialog-header {
  padding: 15px;
  font-size: 20px;
  font-weight: 700;
}

.p-dialog-close-button {
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
}

@media only screen and (min-width: 768px) {
  .p-dialog-header {
    padding: 20px;
    font-size: 26px;
  }
}
p-dialog .p-dialog-content form .field {
  display: flex;
  flex-direction: column;
}
p-dialog .p-dialog-content form .field .p-datepicker {
  width: 100%;
}
p-dialog .p-dialog-content form .field input {
  color: #46494f;
}

.p-datatable .p-paginator {
  justify-content: end;
}

.p-inputtext {
  font-size: 14px;
}

.p-datatable .p-datatable-thead > tr > th {
  font-size: 14px;
}

.p-datatable .p-datatable-tbody > tr > td {
  font-size: 14px;
  padding-top: 0;
  padding-bottom: 0;
}

.p-paginator {
  font-size: 14px;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  font-size: 14px;
}

.display-general-mindit-tables .table-dropdowns .p-dropdown {
  width: 14rem;
}
.display-general-mindit-tables.mobile .p-datatable .p-datatable-header {
  border: none;
  padding: 0rem 0rem;
}
.display-general-mindit-tables.mobile .p-column-filter-row .p-column-filter-clear-button {
  margin-left: 2rem;
}
.display-general-mindit-tables.mobile .p-column-filter-row {
  align-items: baseline;
}
.display-general-mindit-tables.mobile .table-dropdowns {
  width: 100%;
}
.display-general-mindit-tables.mobile .p-input-icon-left > .p-inputtext {
  width: 8rem;
}
.display-general-mindit-tables.mobile .background-white {
  background-color: white;
  padding-bottom: 1rem;
  justify-content: center;
}
.display-general-mindit-tables.mobile .search-input {
  margin-left: 1rem;
  margin-top: 1rem;
}

.tables-company-team .p-datatable .p-datatable-thead > tr > th:nth-child(2) {
  width: 13rem;
}
.tables-company-team .p-datatable .p-datatable-thead > tr > th:nth-child(2).mobile {
  width: inherit;
}

@media only screen and (min-width: 768px) {
  .holidays-table.p-datatable .p-datatable-thead > tr > th {
    padding-left: 2rem;
  }
  .holidays-table.p-datatable .p-datatable-tbody > tr > td {
    padding-left: 2rem;
  }
}
.table-requests-status .p-scroller {
  contain: inherit;
}

.accepted {
  background-color: #c8e6c9;
  color: #256029;
}

.refused {
  background-color: #ffcdd2;
  color: #c63737;
}

.pending {
  background-color: #ffd8b2;
  color: #805b36;
}

.deleted {
  background-color: #e8e8e8;
  color: #606060;
}

.accepted,
.refused,
.pending,
.deleted {
  padding: 2px 14px;
  border-radius: 10px;
  text-align: center;
}

.p-datatable > .p-datatable-wrapper::-webkit-scrollbar {
  height: 8px;
  border-radius: 3px;
  background-color: #f5f5f5;
}

.p-datatable > .p-datatable-wrapper::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #d1d1d1;
}

.selection-calendar-table .p-datatable .p-datatable-thead > tr > th {
  border: 1px solid #dee2e6;
  padding: 0.3rem 1rem;
}
.selection-calendar-table .p-datatable .p-datatable-thead tr > th:first-child {
  border-bottom: none;
}
.selection-calendar-table .p-datatable .p-datatable-thead tr:nth-child(2) > th {
  border-top: none;
}
.selection-calendar-table .p-datatable .p-datatable-tbody > tr > td {
  border: 1px solid #dee2e6;
}

@media screen and (max-width: 500px) {
  .p-datatable .p-paginator {
    justify-content: center;
    padding: 0.5rem 0;
  }
  .p-paginator-bottom .p-dropdown {
    display: none;
  }
}
.p-calendar-w-btn .p-datepicker-trigger {
  border-top-right-radius: 6px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 0;
  margin-left: -2px;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  font-size: 14px;
}

.p-datepicker table th {
  font-size: 14px;
}

table.p-datepicker-calendar td {
  padding: 0;
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .p-calendar-w-btn .p-datepicker-trigger {
    padding: 0.6rem 0;
    width: 2.5rem;
  }
}
.make-a-request-calendars td {
  font-weight: bold;
}
.make-a-request-calendars .p-disabled {
  color: grey;
}

.nomad-calendar td {
  font-weight: bold;
}
.nomad-calendar span.p-disabled {
  font-weight: 300;
}

.burger-menu-sidebar.p-sidebar-left {
  background-color: #46494f;
  width: 100%;
}
.burger-menu-sidebar.p-sidebar .p-sidebar-footer {
  padding: 0;
}
.burger-menu-sidebar.p-sidebar .p-sidebar-header .p-sidebar-close,
.burger-menu-sidebar.p-sidebar .p-sidebar .p-sidebar-header .p-sidebar-icon {
  margin-top: auto;
  color: #fff;
}
.burger-menu-sidebar.p-sidebar .p-sidebar-content {
  padding: 1rem 0;
}
.burger-menu-sidebar.p-sidebar .p-sidebar-header {
  border-bottom: 1px solid #a3a4a7;
}

@media only screen and (min-width: 281px) {
  .burger-menu-sidebar.p-sidebar-left {
    width: 90%;
  }
}
@media only screen and (min-width: 576px) {
  .burger-menu-sidebar.p-sidebar-left {
    width: 60%;
  }
}
.p-button-label {
  font-size: 14px;
}

.p-button.p-button-icon-only {
  width: 2.5rem;
  height: 2.7rem;
}

button.toggle-sidebar-button {
  color: #eeeeee;
}
button.toggle-sidebar-button.p-button.p-button-text:enabled:active {
  background: transparent;
}
button.toggle-sidebar-button.p-button:focus {
  box-shadow: 0 0;
}
button.toggle-sidebar-button.p-button:hover {
  background-color: rgba(224, 23, 40, 0.0392156863);
  color: #eeeeee;
}

.action-btn.p-button.p-button-icon-only {
  width: 2.2rem;
  height: 2.2rem;
}

.update-team-btn span.p-button.p-fileupload-choose {
  padding: 0.5rem 1.25rem;
}

.delete-button .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  display: none;
}

.align-icons .p-tree .p-tree-container .p-treenode .p-treenode-content {
  justify-content: center;
}

.tree-node-menu .p-tree-container {
  overflow: hidden;
}
.tree-node-menu.p-tree {
  background: transparent;
  color: #a3a4a7;
  border: 0 none;
  padding: 0.8rem 0.8rem 0.8rem 0;
}
.tree-node-menu.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  box-shadow: 0 0;
}
.tree-node-menu.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  box-shadow: 0 0;
}

.filters-accordeon.p-accordion .p-accordion-header .p-accordion-header-link {
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #dee2e6;
  border-radius: 0px;
  padding: 1rem 1.25rem;
  font-weight: 500;
}
.filters-accordeon.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  position: absolute;
  right: 1rem;
}
.filters-accordeon.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background-color: #fff;
}
.filters-accordeon.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background-color: #fff;
}
.filters-accordeon.p-accordion .p-accordion-content {
  border: none;
}

.accordion-design.p-accordion .p-accordion-header .p-accordion-header-link {
  background-color: #ebeeef;
}
.accordion-design.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background-color: #f8f9fa;
}

.accordion-header-before.p-accordion .p-accordion-header .p-accordion-header-link {
  display: none;
}
.accordion-header-before.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  display: none;
}
.accordion-header-before.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background-color: #fff;
}
.accordion-header-before.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background-color: #fff;
}
.accordion-header-before.p-accordion .p-accordion-content {
  border: none;
  padding-top: 0;
  padding-bottom: 0;
}

.accordion-header-after.p-accordion .p-accordion-toggle-icon {
  display: none;
}
.accordion-header-after.p-accordion .p-accordion-header .p-accordion-header-link {
  background-color: #fbfbfb;
  padding: 0.7rem;
  font-weight: 500;
  color: #000;
  border-radius: 6px;
}
.accordion-header-after.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background-color: #fdfdfd;
}

.manage-nomad-office .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link {
  background: none;
}
.manage-nomad-office .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none;
}

.edit-user .p-calendar {
  width: 100%;
}
.edit-user .p-multiselect .p-multiselect-clearable {
  width: 100%;
}
.edit-user .p-dropdown {
  width: 100%;
}
.edit-user .p-icon-wrapper {
  display: flex;
}

.profile .p-inputtext {
  width: 100%;
}
.profile .p-button {
  color: #ffffff;
}

.scrollbar-form-location-administration {
  /* Track */
}
.scrollbar-form-location-administration ::-webkit-scrollbar {
  width: 0px;
}
.scrollbar-form-location-administration ::-webkit-scrollbar-track {
  background: #a3a4a7;
}

.custom-listbox .p-listbox .p-listbox-list {
  padding: 0;
}
.custom-listbox .p-listbox .p-listbox-list .p-listbox-item {
  padding: 0;
}
.custom-listbox .p-listbox .p-listbox-list .p-listbox-empty-message {
  font-size: 0.875rem;
}

.classic-calendar-dropdown .p-dropdown.p-component {
  width: 200px;
}

p-dialog.classic-calendar-dialog .p-dialog-mask .p-dialog {
  width: 45rem;
  height: 28rem;
}
p-dialog.classic-calendar-dialog .p-dialog-mask .p-dialog .p-dialog-header {
  padding-bottom: 1rem;
}
p-dialog.classic-calendar-dialog .p-dialog-mask .p-dialog .p-dialog-content {
  padding-bottom: 1rem;
}

p-dialog.classic-calendar-dialog.mobile .p-dialog-mask .p-dialog {
  width: 90%;
  height: 30rem;
}

p-table.modal-table .p-datatable-wrapper .p-datatable-table {
  min-width: 40rem;
  min-height: 17rem;
}

.modal-table .p-datatable .p-datatable-thead > tr > th {
  background-color: #e01728;
  color: white;
  font-weight: normal;
  border-width: 1px;
  padding: 4px;
  text-align: center;
}

.modal-table .p-datatable.p-datatable-gridlines {
  border-color: rgb(185, 182, 182);
}

.modal-table .p-datatable .p-datatable-tbody > tr > td {
  vertical-align: top;
}

.fc-today {
  background-color: white;
}

.fc-col-header-cell .fc-scrollgrid-sync-inner {
  font-size: 15px;
  background-color: #e01728;
}

.fc.fc-theme-standard a.fc-col-header-cell-cushion {
  color: white;
  font-weight: normal;
}

.fc-weekend {
  background-color: hsla(0, 0%, 82%, 0.3);
  pointer-events: none;
}

.fc .fc-daygrid-day-top {
  display: block;
  padding-bottom: 1rem;
}

.fc-day-sat .fc-weekend .fc-daygrid-day-top a,
.fc-day-sun .fc-weekend .fc-daygrid-day-top a {
  color: rgb(169, 167, 167);
}

.fc-day-sat .fc-daygrid-day-frame .fc-daygrid-day-events,
.fc-day-sun .fc-daygrid-day-frame .fc-daygrid-day-events {
  visibility: hidden;
}

.fc .fc-daygrid-day-frame {
  height: 8rem;
}
@media screen and (max-width: 500px) {
  .fc .fc-daygrid-day-frame {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fc .fc-daygrid-day-events .fc-daygrid-event-harness a::after {
  background-color: white;
}

.fc .fc-daygrid-day-events .fc-daygrid-event-harness a::before {
  border-color: none;
}

.fc-event:focus {
  box-shadow: none;
}

.fc-event:hover {
  cursor: pointer;
}

.fc .fc-day-sat.fc-day-disabled {
  background: none;
}

.fc .fc-day-sun.fc-day-disabled {
  background: none;
}

.fc-h-event .fc-event-title-container {
  font-size: 13px;
  text-wrap: balance;
}
@media screen and (max-width: 500px) {
  .fc-h-event .fc-event-title-container {
    font-size: 14px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1050px) {
  .fc-h-event .fc-event-title-container {
    font-size: 11px;
  }
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
  margin-left: 1px;
}

.fc .fc-scroller-liquid-absolute,
.fc .fc-scroller {
  inset: 3px;
}
@media screen and (max-width: 500px) {
  .fc .fc-scroller-liquid-absolute,
  .fc .fc-scroller {
    inset: 0px;
  }
}
.fc .fc-scroller-liquid-absolute::-webkit-scrollbar-track,
.fc .fc-scroller::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #f5f5f5;
}
.fc .fc-scroller-liquid-absolute::-webkit-scrollbar,
.fc .fc-scroller::-webkit-scrollbar {
  height: 9px;
  width: 9px;
  background-color: #f5f5f5;
}
.fc .fc-scroller-liquid-absolute::-webkit-scrollbar-thumb,
.fc .fc-scroller::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #d1d1d1;
}

@media screen and (max-width: 500px) {
  .fc .fc-toolbar {
    flex-direction: row-reverse;
  }
  .fc .fc-toolbar .fc-toolbar-chunk:last-child {
    display: none;
  }
}

label {
  font-size: 14px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: 14px;
}

.p-dropdown-items-wrapper::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #f5f5f5;
}
.p-dropdown-items-wrapper::-webkit-scrollbar {
  height: 9px;
  width: 9px;
  background-color: #f5f5f5;
}
.p-dropdown-items-wrapper::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #d1d1d1;
}

.p-multiselect-label {
  font-size: 14px;
}

.p-listbox-option {
  font-size: 14px;
}

.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 1rem;
}
.p-multiselect-panel .p-multiselect-items {
  padding: 0.5rem 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  font-size: 14px;
  padding: 0.5rem 1rem;
}

.user-multiselect .p-multiselect-items-wrapper {
  max-height: 9rem !important;
}

.phone-input .p-inputtext {
  width: 100%;
}

.nomad-gallery .p-galleria .p-galleria-thumbnail-container {
  background: none;
  padding: 0.5rem 0.25rem;
  height: 5rem;
}
.nomad-gallery .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
  box-shadow: none;
}
.nomad-gallery .p-galleria-item-container chevronrighticon svg, .nomad-gallery .p-galleria-item-container chevronlefticon svg {
  width: 2.3rem;
  height: 2.3rem;
}
.nomad-gallery .p-galleria-thumbnail-prev, .nomad-gallery .p-galleria-thumbnail-next {
  display: none;
}
.nomad-gallery .p-galleria-thumbnail-item {
  height: 4rem;
}
.nomad-gallery .p-link:focus {
  box-shadow: none;
}

.p-fileupload .p-fileupload-content {
  padding: 0;
}
.p-fileupload .p-fileupload-row > div:first-child {
  display: none;
}

.p-message .p-message-summary {
  font-size: 0.9rem;
}
.p-message .p-message-detail {
  font-size: 0.875rem;
}
.p-message .p-message-wrapper {
  padding: 0.5rem;
}