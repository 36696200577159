.p-dialog-header {
  padding: 15px;
  font-size: 20px;
  font-weight: 700;
}

.p-dialog-close-button {
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
}

@media only screen and (min-width: 768px) {
  .p-dialog-header {
    padding: 20px;
    font-size: 26px;
  }
}

p-dialog .p-dialog-content{
  form .field{
    display: flex;
    flex-direction: column;
    .p-datepicker{
      width: 100%;
    }
    input{
     color: #46494f;
    }
  }
}
